import React from "react";
import { Checkbox, DialogContent } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Close } from '@mui/icons-material';

function ViewPreOrderDialog({ onClose, open }) {

    const data = open?.data

    const handleClose = () => {
        onClose();
    };


    return (
        <Dialog onClose={handleClose} fullWidth={true} maxWidth="lg" aria-labelledby="simple-dialog-title" open={open?.isVisible}>
             <div className={`border-2 flex flex-wrap items-center justify-between`}>
                <DialogTitle id="form-dialog-title" className='flex flex-row items-center gap-2'>
                    <span className="text-xl sm:text-2xl">
                        {"Pre Order View"}
                    </span>
                    <button
                        className={`${data?.Status === "Pending" ? "bg-[#11CDEF]" :  data?.Status === "Approved" ? "bg-green-500" : "bg-red-500"}  text-white inline-flex items-center px-4 py-1.5 rounded-md  text-sm font-medium`}
                    >
                        {data?.Status}
                    </button>
                </DialogTitle>
                <Close className="w-6 h-auto mr-2 -mt-6 cursor-pointer" onClick={handleClose} />
            </div>

            <DialogContent dividers>
                <div className="bg-gray-300 shadow   overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">General Information</h3>
                    </div>
                    <div className="border-t border-gray-200">
                        <dl>
                            <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-12 sm:gap-2 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500 sm:col-span-2">Customer</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-10 border-gray-300">
                                    {data?.CustomerName}
                                </dd>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-6 sm:gap-2 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Collection</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-gray-300">{data?.CollectionName}</dd>
                                <dt className="text-sm font-medium text-gray-500">Logo</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-gray-300">{data?.Logo}</dd>
                                <dt className="text-sm font-medium text-gray-500">Quantity</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{data?.Quantity}</dd>
                            </div>

                            <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-8 sm:gap-2 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Size</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-gray-300">{data?.Size}</dd>
                                <dt className="text-sm font-medium text-gray-500">Buckram</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-gray-300">{data?.Buckram}</dd>
                                <dt className="text-sm font-medium text-gray-500">SKU</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-gray-300">{data?.SKU}</dd>
                                <dt className="text-sm font-medium text-gray-500">MTR</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{data?.MTR}</dd>
                            </div>
                            <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-12 sm:gap-2 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500 sm:col-span-2">Create By</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-10 border-gray-300">
                                    {data?.CreatedByName || "CUSTOMER"}
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>

                <div className="bg-yellow-300 border-yellow-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Story</h3>
                    </div>
                    <div className="border-t border-gray-200">
                        <dl>
                            <div className="bg-gray-50 px-4 py-3 sm:gap-1 sm:px-6">
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                                <span className="">{data?.Story}</span>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
                <div className="bg-red-500 border-red-500 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Extra</h3>
                    </div>
                    <div className="border-t border-gray-200">
                        <dl>
                            <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-6 items-center">
                                <dt className="text-sm font-medium text-gray-500">
                                    <span className="md:border-r border-red-500 pr-3"> <Checkbox checked={data?.Interlock === 1 ? true : false} name="Interlock" color="primary" />Interlock</span>
                                    <span className="md:border-r border-red-500 pr-3"><Checkbox checked={data?.ZigZag === 1 ? true : false} name="ZigZag" color="primary" />ZigZag</span>
                                </dt>

                                <dt className="text-sm font-medium text-gray-500">
                                    <span className="md:border-r border-red-500 pr-3"> <Checkbox checked={data?.Foil === 1 ? true : false} name="Foil" color="primary" />Foil</span>
                                    <span className="md:border-r border-red-500 pr-3"><Checkbox checked={data?.Screen === 1 ? true : false} name="Screen" color="primary" />Screen</span>
                                    <span className="md:border-r border-red-500 pr-3"><Checkbox checked={data?.Print === 1 ? true : false} name="Print" color="primary" />Print</span>
                                </dt>
                            </div>
                        </dl>
                    </div>
                </div>
                <div className="bg-blue-300 border-blue-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">E-Catalogue</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-8 sm:gap-1 sm:px-6">
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-5">
                                <span className=""><Checkbox checked={data?.ECatalogue ? true : false} name="ECatalogue" color="primary" />E-Catalogue</span>
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>

                <div className="bg-blue-500 border-blue-500 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <h3 className="text-xl  text-center font-bold leading-6 text-gray-900">Technical Details</h3>
                    </div>
                    <div className="border-t border-gray-200">
                        <dl>
                            <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-1 sm:gap-4 sm:px-6">
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{data?.TechnicalDetails}</dd>
                            </div>
                        </dl>
                    </div>
                </div>


            </DialogContent>
        </Dialog>
    );
};
export default ViewPreOrderDialog;