import React, { useEffect, useState } from "react";

// @mui/material components
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
// core components


import componentStyles from "assets/theme/views/admin/tables.js";
import { dataService } from "services/data-service";
import { useHistory, useParams } from 'react-router-dom';
import OrdersTable from "../../../../components/Tables/OrdersTable";
import OrderHeader from "components/Headers/OrderHeader";
import OrderFlowStepDialog from "components/dialoges/OrderFlowStepDialog";
import OrderFlowListDialog from "components/dialoges/OrderFlowListDialog";
import { currentUser } from "services/user-service";
import { useSelector } from 'react-redux';
import AddHoldReasonDialog from "components/dialoges/AddHoldReasonDialog";
import AddScrapQuanitityDialog from "components/dialoges/AddScrapQuantityDialog";
import OrderFlowGCDialog from "components/dialoges/OrderFlowGCDialog";
import { OrderFlowIcon } from "components/Icons/CustomIcons";
import { ScrapIcon } from "components/Icons/CustomIcons";
import PrintOrderForm from "components/dialoges/PrintOrder/PrintOrderForm";
import { Flag as FlagIcon, Edit as EditIcon, Visibility as VisibilityIcon, PanTool as PanToolIcon, FileCopy, Print as PrintIcon, PriorityHigh, CheckCircle } from "@mui/icons-material"

import ViewOrderDialog from "components/dialoges/ViewOrders/ViewOrderDialog";
import { GoodsEntryIcon } from "components/Icons/CustomIcons";
import ViewOrdersGEDialog from "components/dialoges/ViewOrdersGEDialog";
import HoldReasonDialog from "components/dialoges/HoldReasonDialog";
//import { Typography } from "@mui/material";
import CharacterBox from "components/Common/CharacterBox";
import ApproxDeliveryDateIcon from "components/Common/ApproxDeliveryDateIcon";
import AddPriorityDialog from "components/dialoges/AddPriorityDialog";
import { useLocation } from 'react-router-dom';
import MyTaskDetailViewDialog from "components/dialoges/MyTaskDetailViewDialog";
import { Tooltip, Typography } from "@mui/material";
import FlowCheckItemDialog from "components/dialoges/FlowCheckItemDialog";


const useStyles = makeStyles(componentStyles);

const OrderFlow = () => {
  const location = useLocation();
  const classes = useStyles();
  const [runningOrderList, setRunningOrderList] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [totalQuantityIn, setTotalQuantityIn] = useState(0);
  const history = useHistory();

  const [searchText, setSearchText] = useState('');
  const [customerSearchText, setCustomerSearchText] = useState('');
  const [orderFilter, setOrderFilter] = useState({
    TagInterlock: null,
    TagZigZag: null,
    BindingsDieCut: null,
    BindingsFoil: null,
    BindingsTagPrinted: null
  });

  const [fileSearchText, setFileSearchText] = useState('');
  const [page, setPage] = useState(1);
  const [orderBy, setOrderByData] = useState('InDate');
  const [order, setOrderData] = useState('asc');
  const [isReload, setIsReload] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const [offset, setOffset] = useState(10);

  const { flow, WorkCenterId } = useParams();

  const [open, setOpen] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [openStartFinish, setOpenStartFinish] = useState(false);
  const [startFinishOrder, setStartFinishOrder] = useState(null);

  const [openShowFlowListView, setOpenShowFlowListView] = useState(null);
  const [flowStepListOrder, setFlowStepListOrder] = useState(null);

  const [openAddHoldReasonDialog, setOpenAddHoldReasonDialog] = useState(null);
  const [holdReasonOrder, setHoldReasonOrder] = useState(null);

  const [openPriorityDialog, setOpenPriorityDialog] = useState(false)
  const [priorityDetail, setPriorityDetail] = useState(false)

  const [printOrderFormData, setPrintOrderFormData] = useState(null);
  const [openPrintOrderForm, setOpenPrintOrderForm] = useState(null);

  const [openAddScrapQuantityDialog, setOpenAddScrapQuantityDialog] = useState(false);
  const [scrapQuantityOrder, setScrapQuantityOrder] = useState(null);

  const [openHoldReason, setOpenHoldReason] = useState(false)

  const [isStart, setIsStart] = useState(null);
  const selectedOrderFlow = useSelector(state => state?.selectedOrderFlow); // show/hide cart sidebar

  const [selectedGCOrders, setSelectedGCOrders] = useState([]);
  const [openGCDialog, setOpenGCDialog] = useState(false);

  const [cuttingTableFilter, setCuttingTableFilter] = useState([]);

  const [goodsDetailDialogData, setGoodsDetailDialogData] = useState(false);
  const [openGoodsDetailDialog, setOpenGoodsDetailDialog] = useState(false);

  const [noReasonHoldOrders, setNoReasonHoldOrders] = useState([])

  const [loadingRows, setLoadingRows] = useState({});

  const [viewDetailDialog, setViewDetailDialog] = useState({
    isVisible: false,
    data: null,
    extraData: null
  });
  const [viewFlowCheckDialog, setViewFlowCheckDialog] = useState({ isVisible: false, data: null, extraData: null })

  // const getLocalStorageProductId = (row) => {
  //   let pId = 0;
  //   if (selectedOrderFlow?.selected_flow) {  
  //     pId = selectedOrderFlow?.selected_flow?.product?.ProductId ? selectedOrderFlow?.selected_flow?.product?.ProductId : 0;
  //   }
  //   return pId;

  // };

  const handleFlowStepCheckItem = async (row) => {
    setLoadingRows((prevRows) => ({
      ...prevRows,
      [row.FlowStepId]: true
    }));
    try {
      let res = await dataService('get', `FlowStepCheckItemList/${row?.FlowStepId}/${row?.ProductId}/${row?.WorkCenterId}`, '');
      if (res.status === 200 && res?.data?.length > 0) {
        setViewFlowCheckDialog({
          isVisible: true, data: res?.data, extraData: row
        })
      }
      setLoadingRows((prevRows) => ({
        ...prevRows,
        [row.FlowStepId]: false
      }));
    } catch (error) {
      setLoadingRows((prevRows) => ({
        ...prevRows,
        [row.FlowStepId]: false
      }));
    }
  }

  const columns = [
    {
      id: 'OrderNumber', numeric: false, disablePadding: true, dataType: 'component', label: 'Order #', Component: (row) => {
        return (
          <ApproxDeliveryDateIcon data={row} />
        )
      }
    },
    // { id: 'OrderNumber', numeric: false, disablePadding: true, label: 'Order #', dataType: 'string' },
    { id: 'ProductName', numeric: false, disablePadding: false, label: 'Product', dataType: 'string' },
    {
      id: 'FileName', numeric: false, disablePadding: false, label: 'File Name', dataType: 'component', Component: (row) => {
        return (
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}>
            {!row?.IsOtherWorkCenter ?
            <Tooltip  title={row?.IsCheckItemsChecked ? "Checklist done" : "Checklist pending"}>
            <CheckCircle fontSize="large" color={row?.IsCheckItemsChecked ? "success" : "error"} onClick={() => handleFlowStepCheckItem(row)} />
            </Tooltip> : null}
            <Typography>{row?.FileName}</Typography>
          </Box>
        )
      }
    },
    { id: 'FileSize', numeric: false, disablePadding: false, label: 'File Size', dataType: 'string' },
    // { id: 'WorkCenterName', numeric: false, disablePadding: false, label: 'Status', dataType:'string'},
    { id: 'CustomerName', numeric: false, disablePadding: false, label: 'Customer', dataType: 'string' },
    { id: 'Client', numeric: false, disablePadding: false, label: 'Client', dataType: 'string' },
    { id: 'Quantity', numeric: false, disablePadding: false, label: 'Total Qty', dataType: 'string' },
    { id: 'FlowStepQuantityIn', numeric: false, disablePadding: false, label: 'In Qty', dataType: 'string' },
    // { id: 'OrderDate', numeric: false, disablePadding: false, label: 'Order Date', dataType:'date'},
    // { id: 'DeliveryDate', numeric: false, disablePadding: false, label: 'Delivery Date', dataType:'date'},
    {
      id: 'StartedSince', numeric: false, disablePadding: false, label: 'In Since', dataType: 'component', Component: (row) => {
        return (
          <>
            <CharacterBox classNames={`text-xl font-extrabold cursor-pointer
                ${row?.StartedSinceColor === 'green' ? "bg-green-600" :
                row?.StartedSinceColor === 'yellow' ? "bg-yellow-600" :
                  row?.StartedSinceColor === 'red' ? "bg-red-600" :
                    row?.StartDate ? 'bg-[#d5d9de]' : "bg-[#d5d9de]"}`} character={row?.StartedSince} />
          </>
        )
      }
    },
    { id: 'FlowStepHoldReason', numeric: false, disablePadding: false, label: 'Hold Reason', dataType: 'string' }
  ];

  useEffect(() => {
    setPage(1);
    setOrderFilter({
      TagInterlock: null,
      TagZigZag: null,
      BindingsDieCut: null,
      BindingsFoil: null,
      BindingsTagPrinted: null
    });
    setCuttingTableFilter([])
    // Perform actions based on route change here
  }, [WorkCenterId]);

  useEffect(() => {
    fetchData()
  }, [page, searchText, customerSearchText, fileSearchText, order, orderBy, offset, isReload, selectedOrderFlow?.selected_flow?.product?.ProductId, cuttingTableFilter, orderFilter])

  const fetchData = async () => {
    setIsLoading(true);
    let cuttingTableNumbers = cuttingTableFilter?.length > 0 ? cuttingTableFilter?.join(',') : '';
    let res = await dataService('get', `FlowOrderList/${WorkCenterId}`, `customerSearchText=${customerSearchText}&fileSearchText=${fileSearchText}&searchText=${searchText}&sortBy=${orderBy}&sortOrder=${order}&page=${page}&offset=${offset}&ProductId=${selectedOrderFlow?.selected_flow?.product?.ProductId}&cuttingTable=${cuttingTableNumbers}&TagInterlock=${orderFilter?.TagInterlock}&TagZigZag=${orderFilter?.TagZigZag}&BindingsDieCut=${orderFilter?.BindingsDieCut}&BindingsFoil=${orderFilter?.BindingsFoil}&BindingsTagPrinted=${orderFilter?.BindingsTagPrinted}`);
    const newRes = res?.data?.map((ele) => ({
      ...ele,
      // HoldReasonDiff : Number(`${moment().diff(ele?.HoldSince, 'days')}`) 
    }))
    setRunningOrderList(newRes);
    setNoReasonHoldOrders(res?.NoReasonHoldOrders)
    setTotalRecord(res?.totalRecord);
    setTotalQuantityIn(res?.TotalQuantityIn || 0);
    setIsLoading(false);
  }

  const editAction = (OrderDetail) => {
    history.push(`/admin/order/${OrderDetail.OrderId}`)
  };

  const viewAction = (OrderDetail) => {
    setOpen(true);
    setOrderId(OrderDetail.OrderId);
  };

  const makeCopyAction = (OrderDetail) => {
    history.push(`/admin/order-copy/${OrderDetail.OrderId}`)
  }


  const startAction = (OrderDetail) => {

    setIsStart(true);
    setOpenStartFinish(true);
    const newDetail = {
      ...OrderDetail,
      noReasonHoldOrders
    }
    setStartFinishOrder(newDetail);
  };

  const finishAction = (OrderDetail) => {
    if (noReasonHoldOrders?.length > 0) {
      setOpenHoldReason(true)
    } else {

      setIsStart(false);
      setOpenStartFinish(true);
      const newDetail = {
        ...OrderDetail,
        noReasonHoldOrders
      }
      setStartFinishOrder(newDetail);
    }
  };

  const FlowSetpListAction = (OrderDetail) => {
    setOpenShowFlowListView(true);
    setFlowStepListOrder(OrderDetail);

  };

  const AddHoldReasonAction = (OrderDetail) => {
    setOpenAddHoldReasonDialog(true);
    setHoldReasonOrder(OrderDetail);
  }

  const ScrapFlowQuantityAction = (OrderDetail) => {
    setOpenAddScrapQuantityDialog(true);
    setScrapQuantityOrder(OrderDetail);

  }

  const generateChallanAction = (GCOrders) => {
    setSelectedGCOrders(GCOrders)
    setOpenGCDialog(true);
  }
  const handlePrintOrderFormDialog = (row) => {
    setOpenPrintOrderForm(true);
    setPrintOrderFormData(row.OrderId);
  };

  const viewGoodsDetailAction = (row) => {
    setGoodsDetailDialogData(row)
    setOpenGoodsDetailDialog(true);
  };

  const handleViewPriority = (row) => {
    setOpenPriorityDialog(true)
    setPriorityDetail(row)
  }

  // IsOtherWorkCenter
  let actionList = [
    { label: 'View', icon: VisibilityIcon, iconColor: 'purple-500', action: viewAction },
    { label: 'Edit', icon: EditIcon, iconColor: 'blue-500', action: editAction, hide : (row) => row?.IsOtherWorkCenter},
    { label: 'Make Copy', icon: FileCopy, iconColor: 'pink-500', action: makeCopyAction, hide : (row) => row?.IsOtherWorkCenter},
    { label: 'Start', icon: FlagIcon, iconColor: 'green-500', action: startAction, hide : (row) => row?.IsOtherWorkCenter},
    { label: 'Finish', icon: FlagIcon, iconColor: 'red-500', action: finishAction, hide : (row) => row?.IsOtherWorkCenter},
    { label: 'FlowSteps', icon: OrderFlowIcon, iconColor: 'yellow-500', action: FlowSetpListAction, hide : (row) => row?.IsOtherWorkCenter},
    { label: 'Hold Reason', icon: PanToolIcon, iconColor: 'red-500', action: AddHoldReasonAction, hide : (row) => row?.IsOtherWorkCenter},
    { label: 'Scrap', icon: ScrapIcon, iconColor: 'red-500', action: ScrapFlowQuantityAction, hide : (row) => row?.IsOtherWorkCenter},
    { label: 'Print Order Form', icon: PrintIcon, iconColor: 'blue-500', action: handlePrintOrderFormDialog, hide : (row) => row?.IsOtherWorkCenter},
    { label: 'Goods Detail', icon: GoodsEntryIcon, iconColor: 'white', action: viewGoodsDetailAction, hide : (row) => row?.IsOtherWorkCenter},
    { label: 'Priority', icon: PriorityHigh, iconColor: 'green-500', action: handleViewPriority, hide : (row) => row?.IsOtherWorkCenter},
  ]

  // remove start action if view is pending orders
  if (flow === 'pending-orders') {
    actionList = actionList.filter(ac => {
      return ac.label !== 'Start'
    })
  }

  if (flow === 'delivery') {
    actionList = actionList.filter(ac => {
      return ac.label !== 'Finish'
    })
  }

  // remove edit action if not super admin
  if (currentUser()?.Role !== 'SUPER_ADMIN') {
    actionList = actionList.filter(ac => {
      return ac.label !== 'Edit'
    })
  }

  const handleRowClick = async (row) => {
    setLoadingRows((prevRows) => ({
      ...prevRows,
      [row.FlowStepId]: true
    }));
    try {
      let res = await dataService('get', `TaskGetByFlowStepId/${row?.FlowStepId}`, '');
      if (res.status === 200 && res?.data) {
        setViewDetailDialog({
          isVisible: true, data: res?.data, extraData: res?.data
        })
      }
      setLoadingRows((prevRows) => ({
        ...prevRows,
        [row.FlowStepId]: false
      }));
    } catch (error) {
      setLoadingRows((prevRows) => ({
        ...prevRows,
        [row.FlowStepId]: false
      }));
    }
  }


  return (
    <>
      <OrderHeader />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="0.5rem"
        classes={{ root: classes.containerRoot }}
      >
        <OrdersTable
          view="Orders"
          title={flow?.replace('-', ' ').replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}
          columns={columns}
          rows={runningOrderList}
          page={page - 1}
          total={totalRecord}
          totalQuantityIn={totalQuantityIn}
          pagedata={setPage}
          setSearchText={setSearchText}
          setCustomerSearchText={setCustomerSearchText}
          setFileSearchText={setFileSearchText}
          order={order}
          orderBy={orderBy}
          orderData={setOrderData}
          orderByData={setOrderByData}
          editAction={editAction}
          setOffset={setOffset}
          offset={offset}
          actions={actionList}
          generateChallanAction={generateChallanAction}
          loading={isLoading}
          cuttingTableFilter={cuttingTableFilter}
          setCuttingTableFilter={setCuttingTableFilter}
          setOrderFilter={setOrderFilter}
          orderFilter={orderFilter}
          isOrderFlow={true}
          handleRowClick={handleRowClick}
          loadingRows={loadingRows}
          history={history}
          WorkCenterId={WorkCenterId}
        // viewAction={viewAction}
        // startAction={startAction}
        // finishAction={finishAction}
        />
      </Container>

      {orderId && open &&
        <ViewOrderDialog open={open} onClose={setOpen} OrderId={orderId} setIsReload={setIsReload} isReload={isReload} />
      }

      {startFinishOrder && openStartFinish &&

        <OrderFlowStepDialog open={openStartFinish} onClose={setOpenStartFinish} Order={startFinishOrder} WorkCenterId={WorkCenterId} IsStart={isStart} setIsReload={setIsReload} isReload={isReload} />
      }

      {flowStepListOrder && openShowFlowListView &&
        <OrderFlowListDialog open={openShowFlowListView} viewDialogData={{ title: "Test" }} onClose={setOpenShowFlowListView} OrderDetail={flowStepListOrder} />
      }

      {holdReasonOrder && openAddHoldReasonDialog &&
        <AddHoldReasonDialog open={openAddHoldReasonDialog} Order={holdReasonOrder} onClose={setOpenAddHoldReasonDialog} setIsReload={setIsReload} isReload={isReload} />
      }

      {scrapQuantityOrder && openAddScrapQuantityDialog &&
        <AddScrapQuanitityDialog open={openAddScrapQuantityDialog} Order={scrapQuantityOrder} onClose={setOpenAddScrapQuantityDialog} setIsReload={setIsReload} isReload={isReload} />
      }

      {selectedGCOrders?.length > 0 && openGCDialog &&
        <OrderFlowGCDialog open={openGCDialog} onClose={setOpenGCDialog} Order={startFinishOrder} selectedGCOrders={selectedGCOrders} setIsReload={setIsReload} isReload={isReload} />
      }

      {printOrderFormData && openPrintOrderForm &&
        <PrintOrderForm open={openPrintOrderForm} onClose={setOpenPrintOrderForm} OrderId={printOrderFormData} />
      }

      {openGoodsDetailDialog &&
        <ViewOrdersGEDialog onClose={setOpenGoodsDetailDialog} open={openGoodsDetailDialog} OrderDetail={goodsDetailDialogData} />
      }

      {openHoldReason &&
        <HoldReasonDialog open={openHoldReason} onClose={setOpenHoldReason} Order={noReasonHoldOrders} />
      }

      {priorityDetail && openPriorityDialog &&
        <AddPriorityDialog open={openPriorityDialog} priority={priorityDetail} filters={{ ProductId: selectedOrderFlow?.selected_flow?.product?.ProductId, CuttingTable: cuttingTableFilter?.length > 0 ? cuttingTableFilter?.join(',') : '' }} onClose={setOpenPriorityDialog} setIsReload={setIsReload} isReload={isReload} />
      }

      {viewDetailDialog?.isVisible &&
        <MyTaskDetailViewDialog
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setIsReload={setIsReload}
          isReload={isReload}
          open={viewDetailDialog}
          setOpen={setViewDetailDialog}
          onClose={() => {
            setViewDetailDialog({ data: null, isVisible: false, extraData: null })
          }} />}
      {viewFlowCheckDialog.isVisible &&
        <FlowCheckItemDialog
          setIsReload={setIsReload}
          isReload={isReload}
          open={viewFlowCheckDialog}
          onClose={() => {
            setViewFlowCheckDialog({ data: null, isVisible: false, extraData: null })
          }}
        />
      }
    </>
  );
};

export default OrderFlow;